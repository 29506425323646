// Include the different bits needed in this page
// @codekit-prepend "./util/util"
// @codekit-prepend "./shared/stick"
// @codekit-prepend "./lib/swiper.min.js"


let Home = {

    sliderTitle : document.querySelector('.slider-home-text'),

    // Liftoff Function
    liftoff : function() {
        this.initSliderIntro();
        this.initSliderLatest();
    },


    // Initialize the intro slider
    initSliderIntro : function(){
        setInterval(function(){

            let active = document.querySelector('.slide-active')
            let next   = active.nextElementSibling.classList.contains('slider-home-slide') ? active.nextElementSibling : active.parentElement.firstElementChild
            let title  = next.dataset.title
            next.classList.add('slide-active')
            active.classList.remove('slide-active')

            Home.sliderTitle.innerHTML = title

        } , 5000)

    },


    // Latest news slider
    initSliderLatest : function(){

        var elem = document.querySelector('.flickity-latest');
        var flkty = new Flickity( elem, {
            
            // options
            cellAlign       : 'left',
            contain         : true,
            cellSelector    : '.latest-slide',
            pageDots        : false,
            prevNextButtons : false,
            imagesLoaded    : true,

        });

    }
};

Home.liftoff();